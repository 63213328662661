import React, { Component } from 'react'
import styles from './index.module.scss'
import SeoHead from 'Components/SeoHead'
import Layout from 'Components/Layout'
import AppActions from 'AppActions'
import AppConstants from 'AppConstants'
import Breadcrumbs from 'Components/UI/Breadcrumbs'

class SimplePage extends Component {
  constructor(props) {
    super(props)
    this.breadcrumbsData = [
      {
        path: `/${this.props.pageContext.slug}`,
        title: this.props.pageContext.title
      }
    ]
  }
  componentDidMount() {
    setTimeout(AppActions.finishLoadingPage, AppConstants.LOADING_PAGE_TIMEOUT)
  }
  componentDidUpdate() {
    setTimeout(AppActions.finishLoadingPage, AppConstants.LOADING_PAGE_TIMEOUT)
  }
  render() {
    return (
      <Layout>
        <SeoHead
          title={`${this.props.pageContext.title}`}
          path={`/${this.props.pageContext.slug}`}
        />
        <Breadcrumbs items={this.breadcrumbsData}/>
        <div className='u-height--120' />
        <div className={`u-offset-md-2 u-col-md-8 u-offset-lg-1 u-col-lg-10 u-pad-x--md`}>
          <h1 className={`t-big-title u-uppercase`}>{this.props.pageContext.title}</h1>
          <div className={`${styles.content} t-paragraph-2`} dangerouslySetInnerHTML={{ __html: this.props.pageContext.content }}></div>
        </div>
        <div className='u-height--120' />
      </Layout>
    )
  }
}

export default SimplePage
